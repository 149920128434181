// import React, { useEffect, useState } from 'react';
// import { getSocialMediaData } from '../../lib/socialMedia';
// import Link from "next/link";


// function SocialMedia() {

//     const [socialMediaData, setSocialMediaData] = useState<any>(null);

//     useEffect(() => {
//       const fetchSocialMediaData = async () => {
//         try {
//           const data = await getSocialMediaData();
//           setSocialMediaData(data);
//         } catch (error) {
//           console.error('Error:', error);
//         }
//       };
  
//       fetchSocialMediaData();
//     }, []);

//   return (
//     <ul>
//         {
//             socialMediaData &&  socialMediaData.facebookUrl && (
//                 <li><Link href={socialMediaData?.facebookUrl} target="_blank" aria-label="facebook"><i className="fa fa-facebook"></i></Link></li>
//             )
//         }
//         {
//             socialMediaData &&  socialMediaData.tweeterUrl && (
//                 <li><Link href={socialMediaData?.tweeterUrl} target="_blank" aria-label="twitter"><i className="fa fa-twitter"></i></Link></li>
//             )
//         }
//         {
//             socialMediaData &&  socialMediaData.instagramUrl && (
//                 <li><Link href={socialMediaData?.instagramUrl} target="_blank" aria-label="instagram"><i className="fa fa-instagram"></i></Link></li> 
//             )
//         }
//         {
//             socialMediaData &&  socialMediaData.linkedinUrl && (

//                <li><Link href={socialMediaData?.linkedinUrl} target="_blank" aria-label="linkedin"><i className="fa fa-linkedin"></i></Link></li> 
//             )
//         }
//     </ul>
//   )
// }

// export default SocialMedia;

import React, { useEffect, useState } from 'react';
import { getSocialMediaData } from '../../lib/socialMedia';
import Link from "next/link";

function SocialMedia() {
    const [loading, setLoading] = useState(true);
    const [socialMediaData, setSocialMediaData] = useState<any>(null);

    useEffect(() => {
          const fetchSocialMediaData = async () => {
           try {
            const data = await getSocialMediaData();
            setSocialMediaData(data);
            setLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
            };
             fetchSocialMediaData();
        }, []);

    return (
        <ul>
            {loading ? (
                // Render skeleton or loading state
               <>
                  <li><Link href="/" target="_blank" aria-label="facebook"><i className="fa fa-facebook"></i></Link></li>
                  {/* <li><Link href="/" target="_blank" aria-label="twitter"><i className="fa fa-twitter"></i></Link></li> */}
                  <li><Link href="/" target="_blank" aria-label="instagram"><i className="fa fa-instagram"></i></Link></li>
                  <li><Link href="/" target="_blank" aria-label="linkedin"><i className="fa fa-linkedin"></i></Link></li>
               </>
            ) : (
                // Render social media links if data is available
                <>
                    {socialMediaData && socialMediaData.facebookUrl && (
                        <li><Link href={socialMediaData?.facebookUrl} target="_blank" aria-label="facebook"><i className="fa fa-facebook"></i></Link></li>
                    )}
                    {socialMediaData && socialMediaData.tweeterUrl && (
                        <li><Link href={socialMediaData?.tweeterUrl} target="_blank" aria-label="twitter"><i className="fa fa-twitter"></i></Link></li>
                    )}
                    {socialMediaData && socialMediaData.instagramUrl && (
                        <li><Link href={socialMediaData?.instagramUrl} target="_blank" aria-label="instagram"><i className="fa fa-instagram"></i></Link></li>
                    )}
                    {socialMediaData && socialMediaData.linkedinUrl && (
                        <li><Link href={socialMediaData?.linkedinUrl} target="_blank" aria-label="linkedin"><i className="fa fa-linkedin"></i></Link></li>
                    )}
                </>
            )}
        </ul>
        
    );
}

export default SocialMedia;
