import {fetchAPI} from './api'

export async function getSocialMediaData() {
    const query = `
    query getSocialMediaData {
        themeOptionsSocialMedia {
          socialMedia {
            facebookUrl
            tweeterUrl
            instagramUrl
            linkedinUrl
          }
        }
      }
    `;
    try {
      const data = await fetchAPI(query);
      const socialMedia = data?.themeOptionsSocialMedia?.socialMedia || null;
      return socialMedia;
    } catch (error) {
      console.error('Error fetching social data:', error);
      return null;
    }
  }
  
